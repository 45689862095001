<template>
    <div id="workWithUs" class="col-sm-12 nopadding justify-content-center row">
        <div class="col-sm-10 justify-content-certer row">
            <div class="col-sm-12 justify-content-center row">
                <div class="col-sm-7 divTitleWorkWithUs">
                    <span>
                        Deseja trabalhar conosco?
                    </span>
                </div>
                <div class="col-sm-7 divTextWorkWithUs mt-2">
                    <span>
                        Envie seu currículo e faça parte da equipe Eagle Track!
                    </span>
                </div>
            </div>
            <div class="col-sm-12 divButtonWork mt-2">
                <button class="buttonWork" @click="workWithUs">ENVIAR AGORA</button>
            </div>
        </div>
        <b-modal 
            @show="modalAberto" 
            @hide="modalFechado" 
            size="lg"  
            id="modalEagle"
            title="Preencha todas as informações">
            <slot name="modalBody">
                <div class="justify-content-center row">
                    <div class="container">
                        <b-overlay :show="loadingButton" rounded="sm">
                            <template #overlay>
                                <div class="text-center">
                                <b-icon icon="cloud-arrow-up" font-scale="3" animation="fade"></b-icon>
                                <p id="cancel-label">Por favor, aguarde...</p>
                                </div>
                            </template>
                            <div class="col-sm-12 row nopadding">
                                <div class="col-sm-6 mt-1">
                                    <label class='subtitle'>
                                        Nome:
                                    </label>
                                    <input
                                        id="inputTextName"
                                        type="text"
                                        class='form-control'
                                        v-model="inputText"
                                    />
                                </div>
                                <div class="col-sm-6 mt-1">
                                    <label class='subtitle'>
                                        Email:
                                    </label>
                                    <input
                                        id="inputTextEmail"
                                        type="email"
                                        class='form-control'
                                        v-model="inputEmail"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-12 mt-1">
                                <label class='subtitle'>
                                    Telefone:
                                </label>
                                <the-mask
                                    ref="refTextTelefone"
                                    id="inputTextTelefone"
                                    :masked="true" 
                                    :mask="['(##) #####-####', '(##) ####-####']"
                                    :value="inputTelefone" 
                                    v-model="inputTelefone"
                                    name="inputTextTelefone" 
                                    class="form-control"
                                />
                            </div>
                            <form enctype="multipart/data" @click="onPickFile" class="cursor-pointer">
                                <div class="col-sm-12 mt-1">
                                    <label class='subtitle cursor-pointer'>
                                        Clique para selecionar um arquivo
                                    </label>
                                    <div class="divUpload">
                                        <input
                                            id="inputTextFile"
                                            class='form-control cursor-pointer'                                        
                                            readonly
                                            :value='arquivoValue'
                                        />
                                        <input
                                            v-show="false"
                                            accept="image/png, image/jpeg, image/jpg, .pdf"
                                            type='file'
                                            class="input-file"
                                            ref='fileInput'
                                            @change='onFilePicked'
                                        />
                                    </div>
                                    <label class='subtitle small'>
                                        Arquivos permitidos: .png, .jpeg, .jpg, .pdf
                                    </label>
                                </div>
                            </form>
                        </b-overlay>
                    </div>
                </div>
            </slot>
            <slot slot="modal-footer">
                <span class="text-muted small col-sm-12 text-justify text-lgpd nopadding">
                    Ao clicar em enviar você concorda com o nosso 
                    <span class="texto-azul" 
                        @click="$router.push({name:'PoliticaDePrivacidade'})">
                        Termo de Uso 
                    </span> e 
                    <span class="texto-azul" 
                        @click="$router.push({name:'TermosDeUso'})">
                        Política de Privacidade
                    </span>. 
                    Este consentimento serve para atendimento aos requisitos da 
                    Lei nº 13.709/18 (Lei Geral de Proteção de Dados).
                </span>
                <b-button  @click="cancelButton" class="btn-can">Cancelar</b-button>
                <b-button  @click="confirmButton" class="btn-env" :disabled="disabledButton">
                    <b-spinner small type="grow" v-show="loadingButton"/>
                    Enviar
                </b-button>
            </slot>
        </b-modal>
    </div>
</template>

<script lang="js">
import Vue from 'vue'
// import axios from 'axios'
// import AxiosPlugin from 'vue-axios-cors';
// import jsonpAdapter from 'axios-jsonp'
import {TheMask} from 'vue-the-mask'
import Swal from 'sweetalert2'
export default Vue.extend({
    components:{
        TheMask,
    },
    data(){
        return {
            arquivo:'',
            inputText:'',
            arquivoUrl:'',
            inputEmail:'',
            arquivoValue:'',
            valueArquivo:'',
            inputTelefone:'',
            loadingButton:false,
            disabledButton:false,
        }
    },
    methods:{
        scroll(){
            // window.scroll(0,1)
        },
        workWithUs(){
            this.$bvModal.show('modalEagle')
        },
        modalAberto(){
            // codigo kkk
        },
        modalFechado(){
            // this.$emit('modalClose');
        },
        cancelButton(){
            this.$bvModal.hide('modalEagle')
        },
        async confirmButton(){
            var url = ''
            if(process.env.NODE_ENV === 'production'){
                url = 'https://track.eagletrack.com.br/api'
            }else{
                url = 'http://127.0.0.1:8000/api'
            }
            this.loadingButton = true
            this.disabledButton = true
            var objEnviado = {
                nome: this.inputText,
                email: this.inputEmail,
                arquivo: this.arquivoUrl,
                telefone: this.inputTelefone,
                arquivoName: this.arquivoValue,
            }
            var cond1 = this.inputText.length > 0
            var cond2 = this.inputEmail.length > 0
            var cond3 = this.arquivoUrl.length > 0
            var cond4 = this.inputTelefone.length >= 14
            var cond5 = this.arquivoValue.split('.')[1] == 'png'  ||
                        this.arquivoValue.split('.')[1] == 'jpeg' ||
                        this.arquivoValue.split('.')[1] == 'jpg'  ||
                        this.arquivoValue.split('.')[1] == 'pdf'  ||
                        this.arquivoValue.split('.')[1] == 'JPEG' ||
                        this.arquivoValue.split('.')[1] == 'JPG'  ||
                        this.arquivoValue.split('.')[1] == 'PDF'  ||
                        this.arquivoValue.split('.')[1] == 'PNG'  
            if(cond1 && cond2 && cond3 && cond4 && cond5){
                const options = {
                    // https://track.eagletrack.com.br/api
                    url: `${url}/apresentacao/eagle/trabalhe/conosco`,
                    method: 'POST',
                    data: {
                        objEnviado
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                this.$axios(options).then(() =>{
                    this.loadingButton = false
                    this.disabledButton = false
                    Swal.fire({
                        icon:  'success',
                        title: 'Obrigado!',
                        html:  '<apan> Retornaremos seu contato o mais breve possível. </span>',
                        showConfirmButton: false,
                        timer: 4500
                    })
                    this.inputText     = ''
                    this.inputEmail    = ''
                    this.arquivoUrl    = ''
                    this.arquivoValue  = ''
                    this.inputTelefone = ''
                }).catch(() => {
                    this.loadingButton = false
                    this.disabledButton = false
                    Swal.fire({
                        icon:  'error',
                        title: 'Oops... <br> Algo inesperado aconteceu',
                        html:  '<apan> Tente novamente mais tarde</span> <br> <span class="small"> Verifique sua internet ou seu arquivo </span>',
                        footer: '',
                    })
                })
            }else{
                if(!cond1){
                    this.addClassRed('inputTextName')
                }
                if(!cond2){
                    this.addClassRed('inputTextEmail')
                }
                if(!cond3 || !cond5){
                    this.addClassRed('inputTextFile')
                }
                if(!cond4){
                    this.addClassRed('inputTextTelefone')
                }
                this.loadingButton = false
                this.disabledButton = false
            }
        },

        addClassRed(id){
            var elemento = document.getElementById(id);
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('bordaError');
            if (getIndex === -1) {
                classes.push('bordaError');
                elemento.className = classes.join(' ');
            }
            setTimeout(() => {
                this.removeClassRed(id)
            }, 3000);
        },
        removeClassRed(id){
            var elemento = document.getElementById(id);
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('bordaError');
            if (getIndex > -1) {
                classes.splice(getIndex, 1);
            }
            elemento.className = classes.join(' ');
        },


        ////////////////////////////////////////////////////////////////////////
        onFilePicked(event) {
            const files = event.target.files
            this.valueArquivo = files
            this.arquivoValue = files[0].name
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.arquivoUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.arquivo = files[0]
        },
        onPickFile(){
            this.$refs.fileInput.click()
        },
    }
})
</script>

<style lang="scss">
    .btn-env{
        border-radius: 0px !important;
        border: 1px solid #2196f3 !important;
        color: #2196f3 !important;
        background-color:#fff !important;
        border-radius: 0px !important;
        &:hover{
            color: #FFF !important;
            background-color: #2196f3 !important;
        }
    }
    .btn-can{
        border: 1px solid red !important;
        color: red !important;
        background-color:#fff !important;
        border-radius: 0px !important;
        &:hover{
            color: #FFF !important;
            background-color: red !important;
        }
    }
    .input-file{
        // transform: translateY(-110%);
        width: 100%;
        // z-index: 1 !important;
        opacity: 0 !important;
        // background-color:red;
    }
    .cursor-pointer{
        cursor: pointer;
    }
    .form-control{
        border-radius: 0px !important;
    }
    #workWithUs{
        padding-top: 30px !important;

        .divTitleWorkWithUs{
            font-size: 35px;
            font-family: nexabold;
        }

        .divTextWorkWithUs{
            font-size: 30px;
        }

        .divButtonWork{
            .buttonWork{
                font-size: 17px;
                color: #fff;
                text-decoration: none;
                width: 35%;
                padding: 10px;
                background: #32b8dd;
                border: none;
            }
        }
    }
</style>