<template>
    <div id="wantTalkBlock" class="col-sm-12 nopadding justify-content-center row">
        <div class="col-sm-10 justify-content-center row">
            <div class="col-sm-12 justify-content-center row marginTopResponsive">
                <div class="col-sm-7 divTextPageWantTalk ">
                    Preencha o formulário para entrar em contato ou para solicitar um orçamento.
                </div>
            </div>       
            <div class="col-sm-12 divFormulario row nopadding">
                <div class="col-sm-6 row nopadding iframe justify-content-center">
                    <iframe height="700" class="col-sm-12 nopadding" src="formulario.html" frameborder="0"/>
                    <span class="text-muted small col-sm-12 text-justify text-lgpd nopadding">
                        Ao clicar em enviar você concorda com o nosso 
                            <span class="texto-azul" 
                                @click="$router.push({name:'PoliticaDePrivacidade'})">
                                Termo de Uso 
                            </span> e 
                            <span class="texto-azul" 
                                @click="$router.push({name:'TermosDeUso'})">
                                Política de Privacidade
                            </span>. 
                        Este consentimento serve para atendimento aos requisitos da 
                        Lei nº 13.709/18 (Lei Geral de Proteção de Dados).
                    </span>
                </div>
                <!-- 
                    isso ta comentado para caso um dia volte a usar esse 
                    formulario para entrar em contato, ou der pau no pipeDrive 
                -->
                <!-- <div class="col-sm-6 divFormularioWantTalk">
                    <div class="col-sm-12 divFormularioWantTalk animation-input-1">
                        <div class="col-sm-12 nopadding divDescriptionInput">NOME:</div>
                        <input 
                            id="contactName"
                            :disabled="loadingButton"
                            type="text" 
                            class="form-control" 
                            v-model="contactName" 
                            name="namePearsonContact">
                    </div>
                    <div class="col-sm-12 divFormularioWantTalk animation-input-2">
                        <div class="col-sm-12 nopadding divDescriptionInput">EMPRESA:</div>
                        <input 
                            id="contactEmpresa"
                            :disabled="loadingButton"
                            type="text" 
                            class="form-control" 
                            v-model="contactEmpresa" 
                            name="empresaPearsonContact">
                    </div>
                    <div class="col-sm-12 divFormularioWantTalk animation-input-3">
                        <div class="col-sm-12 nopadding divDescriptionInput">EMAIL:</div>
                        <input 
                            required
                            id="contactEmail"
                            :disabled="loadingButton"
                            type="email" 
                            class="form-control" 
                            v-model="contactEmail" 
                            name="emailPearsonContact">
                    </div>
                    <div class="col-sm-12 divFormularioWantTalk animation-input-4">
                        <div class="col-sm-12 nopadding divDescriptionInput">TELEFONE:</div>
                        <the-mask 
                            id="contactFone"
                            :disabled="loadingButton"
                            :masked="true" 
                            :mask="['(##) #####-####', '(##) ####-####']" 
                            v-model="contactFone" 
                            :value="contactFone" 
                            name="fonePersonFranchising" 
                            class="form-control"
                        />
                    </div>
                    <div class="col-sm-12 divFormularioWantTalk animation-input-5">
                        <div class="col-sm-12 nopadding divDescriptionInput">MENSAGEM:</div>
                        <textarea 
                            id="contactMsg"
                            :disabled="loadingButton"
                            v-model="contactMsg" 
                            class="form-control min-heig" 
                            name="msgPearsonContact">
                        </textarea>
                    </div>
                    <div class="col-sm-12 divButtonForm">
                        <button :disabled="loadingButton" class="buttonSendFormContact" @click="sendFormContact">
                            <b-spinner class="my-auto" small type="grow" v-show="loadingButton"/>
                            <span class="my-auto">
                                ENVIAR
                            </span>
                        </button>
                    </div>
                </div> -->
                <div class="col-sm-6 divMapaContato nopadding">
                    <mapa></mapa>
                    <div id="divAtendimentoEagle" class="col-sm-12 row">
                        <div class="col-sm-6 divAtendimento nopadding">
                            <div class="col-sm-12 nopadding divText">Atendimento</div>
                            <div class="col-sm-12 nopadding divText">(49) 3340 0770</div>
                            <div class="col-sm-12 nopadding divText">(49) 99172 9123</div>
                        </div>
                        <div class="col-sm-6 divEmail nopadding">
                            <div class="col-sm-12 nopadding divText">E-mail</div>
                            <div class="col-sm-12 nopadding divText">eagle@eagletrack.com.br</div>
                            <div class="col-sm-12 nopadding divText">marketing@eagletrack.com.br</div>
                            <div class="col-sm-12 nopadding divText">comercial@eagletrack.com.br</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script lang="js">
import Vue from 'vue'
// import html from '@/components/Blocks/Contato/piepeDrive.html'
import { required, email } from 'vuelidate/lib/validators'
////////////////////////////////
// import AxiosPlugin from 'vue-axios-cors'
// import jsonpAdapter from 'axios-jsonp'
// import {TheMask} from 'vue-the-mask'
import Swal from 'sweetalert2'
export default Vue.extend({
    validations:{
        form:{
            contactMsg:{required},
            contactName:{required},
            contactEmpresa:{required},
        },
        contactEmail:{email, required},
    },
    components:{
        // TheMask,
        mapa: require('@/components/Elementos/mapa').default,
    },
    data(){
        return{
            loadingButton:false,
            contactMsg: '',
            contactName: '',
            contactFone: '',
            contactEmail: '',
            contactEmpresa: '',
        }
    },
    methods:{
        scroll(){
            // window.scroll(0, 1)
        },

        async sendFormContact(){
            var emailValido = !this.$v.contactEmail.$invalid
            var numeroValido = !(this.contactFone <= 14)
            var url = ''
            if(process.env.NODE_ENV === 'production'){
                url = 'https://track.eagletrack.com.br/api'
            }else{
                url = 'http://127.0.0.1:8000/api'
            }
            if((numeroValido || emailValido) && this.$v.form.$invalid){
                this.loadingButton = true
                var objContact = {
                    'nome': this.contactName,
                    'empresa': this.contactEmpresa,
                    'email': this.contactEmail,
                    'telefone': this.contactFone,
                    'mensagem': this.contactMsg,
                }
                // var string = JSON.stringify(objContact)
                const options = {
                    // https://track.eagletrack.com.br/api
                    url: `${url}/apresentacao/eagle/arquivo/email`,
                    method: 'POST',
                    data: {
                        objContact
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                this.$axios(options)
                    .then(() =>{
                        this.loadingButton = false
                        Swal.fire({
                            icon: 'success',
                            title: 'Obrigado!',
                            html: '<apan> Retornaremos seu contato o mais breve possível. </span>',
                            showConfirmButton: false,
                            timer: 4500
                        })
                        this.contactMsg     = '' 
                        this.contactFone    = '' 
                        this.contactName    = ''
                        this.contactEmail   = '' 
                        this.contactEmpresa = '' 
                    }).catch(() => {
                        this.loadingButton = false
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops... <br> Algo inesperado aconteceu',
                            html: '<apan> Tente novamente mais tarde </span><br><span class="small"> Verifique sua internet</span>',
                            footer: '',
                        })
                    })
            }else{
                if(!this.contactMsg){
                    this.addClassRed('contactMsg')
                }
                if(!this.contactName){
                    this.addClassRed('contactName')
                }
                if(!(this.contactFone.length >= 14)){
                    this.addClassRed('contactFone')
                }
                if(!emailValido){
                    this.addClassRed('contactEmail')
                } 
                if(!this.contactEmpresa){
                    this.addClassRed('contactEmpresa')
                }
            }
            
        },

        addClassRed(id){
            var elemento = document.getElementById(id);
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('bordaError');
            if (getIndex === -1) {
                classes.push('bordaError');
                elemento.className = classes.join(' ');
            }
            setTimeout(() => {
                this.removeClassRed(id)
            }, 3000);
        },

        removeClassRed(id){
            var elemento = document.getElementById(id);
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('bordaError');
            if (getIndex > -1) {
                classes.splice(getIndex, 1);
            }
            elemento.className = classes.join(' ');
        },

    },
})
</script>

<style lang="scss" scoped>
    #wantTalkBlock{
        padding-top: 30px !important;
        .divTitlePageWantTalk{
            font-size: 35px;
            font-family: nexabold;
        }
        @media screen and (min-width: 220px) {
            .text-lgpd{
                transform: translateY(-10%);
            }
        }
        @media screen and (min-width: 320px) {
            .text-lgpd{
                transform: translateY(-60%);
            }
        }
        @media screen and (min-width: 420px) {
            .text-lgpd{
                transform: translateY(-120%);
            }
        }
        @media screen and (min-width: 520px) {
            .text-lgpd{
                transform: translateY(-150%);
            }
        }
        @media screen and (min-width: 620px) {
            .text-lgpd{
                transform: translateY(-35%);
            }
        }
        @media screen and (min-width: 720px) {
            .text-lgpd{
                transform: translateY(-40%);
            }
        }
        @media screen and (min-width: 820px) {
            .text-lgpd{
                transform: translateY(-50%);
            }
        }
        @media screen and (min-width: 920px) {
            .text-lgpd{
                transform: translateY(-80%);
            }
        }
        @media screen and (min-width: 1020px) {
            .text-lgpd{
                transform: translateY(-80%);
            }
        }
        @media screen and (min-width: 1120px) {
            .text-lgpd{
                transform: translateY(-100%);
            }
        }
        @media screen and (min-width: 1220px) {
            .text-lgpd{
                transform: translateY(-100%);
            }
        }
        @media screen and (min-width: 1320px) {
            .text-lgpd{
                transform: translateY(-100%);
            }
        }
        @media screen and (min-width: 1420px) {
            .text-lgpd{
                transform: translateY(-130%);
            }
        }
        @media screen and (min-width: 1520px) {
            .text-lgpd{
                transform: translateY(-130%);
            }
        }
        @media screen and (min-width: 1620px) {
            .text-lgpd{
                transform: translateY(-130%);
            }
        }
        @media screen and (min-width: 1920px) {
            .text-lgpd{
                transform: translateY(-150%);
            }
        }
        .iframe{
            animation: fade-lado 1s cubic-bezier(.17,.67,.49,1.02);
        }
        // .animation-input-1{
        //     animation: fade-lado 0.8s   cubic-bezier(.17,.67,.49,1.02);
        // }
        // .animation-input-2{
        //     animation: fade-lado 1.2s   cubic-bezier(.17,.67,.49,1.02);
        // }
        // .animation-input-3{
        //     animation: fade-lado 1.6s   cubic-bezier(.17,.67,.49,1.02);
        // }
        // .animation-input-4{
        //     animation: fade-lado 2s     cubic-bezier(.17,.67,.49,1.02);
        // }
        // .animation-input-5{
        //     animation: fade-lado 2.4s   cubic-bezier(.17,.67,.49,1.02);
        // }
        @keyframes fade-lado {
            0%{
                opacity: 0;
                transform:translate(-20%)
            }
            100%{
                opacity: 1;
                transform:translate(0%)
            }
        }
        .divTextPageWantTalk{
            animation: text-clip 1s cubic-bezier(.17,.67,.49,1.02);
            font-size: 30px;
        }
        .divFormulario{
            padding-top: 30px !important;
            // padding-bottom: 200px;
            // height: 100%;

            .divFormularioWantTalk{
                padding-bottom: 15px;
                
                .divDescriptionInput{
                    text-align: left;
                    font-weight: bold;
                }
                .form-control{
                    border-radius: 0px !important;
                }
                .min-heig{
                    min-height: 50px;
                }


            }
            .divButtonForm{
                .buttonSendFormContact{
                    float: right;
                    font-size: 17px;
                    color: #fff;
                    text-decoration: none;
                    width: 35%;
                    font-weight: bold;
                    padding: 5px;
                    background: #32b8dd;
                    border: none;
                }
            }
            .divMapaContato{
                margin-top: 53px !important;
                animation: animation-mapa 1.5s cubic-bezier(.17,.67,.49,1.02);
                #divAtendimentoEagle{
                    padding-top: 10px;

                    .divAtendimento{
                        text-align: left;
                    }

                    .divText{
                        text-align: left;
                    }
                }
            }
            @keyframes animation-mapa {
                to{
                    clip-path: inset(0 0 0 0);
                    opacity: 1;
                    transform: translate(0%);
                }
                from{
                    clip-path: inset(0 0 0 72%);
                    opacity: 0;
                    transform: translate(30%);
                }
            }
        }
    }
@keyframes text-clip {
  from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
</style>