<template>
    <div id="whatWeOffer" class="col-sm-12 nopadding justify-content-center row">
        <div class="col-sm-10 justify-content-center row" id="ofertas">
            <div class="col-sm-12 divTitleWhatWeOffer clip-letras">
                <span class="what">O QUE </span>
                <span class="offer"> OFERECEMOS </span>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <ul class="listBenefits">
                        <li class="clip-letras">
                            Supervisão de rede
                        </li>
                        <li class="clip-letras">
                            Desenvolvimento de folheteria
                        </li>
                        <li class="clip-letras">
                            Treinamentos ao franqueado e funcionários
                        </li>
                        <li class="clip-letras">
                            Setor de apoio interdepartamental
                        </li>
                        <li class="clip-letras">
                            Manuais da franquia
                        </li>
                        <li class="clip-letras">
                            Desenvolvimento de tabloide
                        </li>
                        <li class="clip-letras">
                            Duração: 10 dias na Unidade Piloto
                        </li>
                        <li class="clip-letras">
                            Suporte e treinamento para o instalador
                        </li>
                        <li class="clip-letras">
                            Manuais da franquia
                        </li>
                        <li class="clip-letras">
                            Consultoria de campo
                        </li>
                        <li class="clip-letras">
                            Aux. na análise e escolha do ponto comercial
                        </li>
                    </ul>
                </div>
                <div class="col-sm-6">
                    <ul class="listBenefits">
                        <li class="clip-letras">
                            Licença de uso do software de rastreamento
                        </li>
                        <li class="clip-letras">
                            Layout e padrões arquitetônicos e visuais
                        </li>
                        <li class="clip-letras">
                            Suporte técnicos digital para o cliente do franqueado
                        </li>
                        <li class="clip-letras">
                            Indicação de fornecedores
                        </li>
                        <li class="clip-letras">
                            Servidores WEB
                        </li>
                        <li class="clip-letras">
                            Sistema informatizado de gestão
                        </li>
                        <li class="clip-letras">
                            Módulos em comodato
                        </li>
                        <li class="clip-letras">
                            Orientações de marketing e vendas
                        </li>
                        <li class="clip-letras">
                            Identificação do público-alvo
                        </li>
                        <li class="clip-letras">
                            Manutenção preventiva integral e atualizações para o sistema do franqueado
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>    
</template>

<script lang="js">
import Vue from 'vue'
export default Vue.extend({
    methods:{
        handleScroll(){
            const els = document.querySelectorAll('#ofertas')
            els.forEach((el)  => {
                if(el.getBoundingClientRect().top <= 528){
                    var clip = document.querySelectorAll('.clip-letras')
                    clip.forEach((clip) => {
                        clip.style.animationPlayState = 'running'
                    })
                }
            })
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
})
</script>

<style lang="scss">
    #whatWeOffer{
        .divTitleWhatWeOffer{
            transform:translateY(-10%);
            padding: 0px 5% !important;
            font-family: nexabold;
            font-size: 35px;
            text-align: center;
            .offer{
                color: #00B9DB;
            }
        }
        .clip-letras{
            animation: text-clip .5s cubic-bezier(.17,.67,.49,1.02);
            animation-play-state: paused;
            animation-fill-mode: forwards;
        }
        @keyframes text-clip {
            0%{
                clip-path: polygon(50% 0%, 100% 0, 100% 0, 100% 0, 61% 0, 26% 0, 0 0, 0 0, 0 0);
            }
            99.9%{
                clip-path: polygon(50% 0%, 100% 0, 100% 43%, 100% 100%, 68% 100%, 32% 100%, 0 100%, 0% 43%, 0 0);
            }
            100%{
                clip-path: none;
            }
        }
        .listBenefits{
            // white-space: nowrap;
            padding: 0px 10%;
            text-align: left;
            width: 100%;
            list-style: none;
            li::before{
                content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: #00B9DB;  /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
        }
    }
    @media (min-width:1350px) {
        .listBenefits{
            white-space: nowrap;
        }
    }
</style>