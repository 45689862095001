<template>
    <div id="formFranchising" class="col-sm-12 justify-content-center nopadding row">
        <div class="col-sm-10 justify-content-center row">
            <div class="col-sm-6 divLeft desktop text-right">
                <span class="spanBlueLetter">PREENCHA O<br> FORMULÁRIO</span>
                <br>
                <span class="spanWhiteLetter">E RECEBA TODAS AS<br> INFORMAÇÕES</span>
            </div>
            <div class="col-sm-6 divLeft mobile pl-4 text-left">
                <span class="spanBlueLetter">PREENCHA O FORMULÁRIO</span>
                <br>
                <span class="spanWhiteLetter">E RECEBA TODAS AS INFORMAÇÕES</span>
            </div>
            <div class="col-sm-6 divRight">
                <div class="col-sm-10 divInputForm">
                    <div class="col-sm-12 nopadding divDescriptionInput">NOME:</div>
                    <input
                        id="franchisingName"
                        :disabled="loadingButton"
                        type="text" 
                        class="form-control" 
                        v-model="franchisingName" 
                        name="namePersonFranchising">
                </div>
                <div class="col-sm-10 divInputForm">
                    <div class="col-sm-12 nopadding divDescriptionInput">SOBRENOME:</div>
                    <input
                        id="franchisingSurname"
                        :disabled="loadingButton"
                        type="text" 
                        class="form-control" 
                        v-model="franchisingSurname" 
                        name="surnamePersonFranchising">
                </div>
                <div class="col-sm-10 divInputForm">
                    <div class="col-sm-12 nopadding divDescriptionInput">EMAIL:</div>
                    <input 
                        id="franchisingEmail"
                        :disabled="loadingButton"
                        type="text" 
                        class="form-control" 
                        v-model="franchisingEmail" 
                        name="emailPersonFranchising">
                </div>
                <div class="col-sm-10 divInputForm">
                    <div class="col-sm-12 nopadding divDescriptionInput">TELEFONE:</div>
                    <the-mask 
                        id="franchisingFone"
                        :disabled="loadingButton"
                        :masked="true" 
                        :mask="['(##) #####-####', '(##) ####-####']" 
                        v-model="franchisingFone" 
                        :value="franchisingFone" 
                        name="fonePersonFranchising" 
                        class="form-control"/>
                </div>
                <div class="col-sm-10 divInputForm">
                    <div class="col-sm-12 nopadding divDescriptionInput">ESTADO:</div>
                    <Multiselect 
                        id="franchisingEstado"
                        :disabled="loadingButton"
                        v-model="franchisingEstado" 
                        :value="'id'" 
                        :label="'nome'" 
                        :options="estados" 
                        :placeholder="'Selecione...'" 
                        :selectLabel="''" 
                        :selectedLabel="'Selecionado'" 
                        :deselectLabel="''" 
                        :multiple="false" 
                        :taggable:="true" 
                        track-by="id" 
                        :max-height="600" 
                        :limit="2" 
                        :limitText="count => `e mais ${count}`" 
                        :closeOnSelect="true" 
                        @input="carregaCidades" 
                        :preselect-first="false">
                        <template slot="noResult">
                            Nenhum elemento encontrado
                        </template>
                        <template slot="noOptions">
                            Nenhum elemento encontrado
                        </template>
                    </Multiselect>
                </div>
                <div class="col-sm-10 divInputForm">
                    <div class="col-sm-12 nopadding divDescriptionInput">CIDADE:</div>
                        <!-- :disabled="!franchisingEstado.length" -->
                    <Multiselect
                        id="franchisingCidade"
                        :loading="loadingCidade"
                        :disabled="loadingButton"
                        v-model="franchisingCidade" 
                        :value="'id'" 
                        :label="'nome'" 
                        :options="cidades" 
                        :placeholder="'Selecione...'" 
                        :selectLabel="''" 
                        :selectedLabel="'Selecionado'" 
                        :deselectLabel="''" 
                        :multiple="false" 
                        :taggable:="true" 
                        track-by="id" 
                        :max-height="600" 
                        :limit="2" 
                        :limitText="count => `e mais ${count}`" 
                        :closeOnSelect="true" 
                        :preselect-first="false">
                        <template slot="noResult">
                            Nenhum elemento encontrado
                        </template>
                        <template slot="noOptions">
                            Nenhum elemento encontrado
                        </template>
                    </Multiselect>
                </div>
                <div class="col-sm-10 divButtonForm">
                    <button class="buttonSendFormFranchising" @click="sendFormFranchising">
                        <b-spinner small type="grow" v-show="loadingButton"/>
                        ENVIAR
                    </button>
                    <div class="row">
                        <span class="text-muted small col-sm-12 text-justify text-lgpd nopadding">
                            Ao clicar em enviar você concorda com o nosso 
                            <span class="texto-azul" 
                                @click="$router.push({name:'PoliticaDePrivacidade'})">
                                Termo de Uso 
                            </span> e 
                            <span class="texto-azul" 
                                @click="$router.push({name:'TermosDeUso'})">
                                Política de Privacidade
                            </span>.
                            Este consentimento serve para atendimento aos requisitos 
                            da Lei nº 13.709/18 (Lei Geral de Proteção de Dados).
                        </span>
                    </div>
                </div>
            </div>
            
        </div>
    </div>    
</template>

<script lang="js">
import Vue from 'vue'
// import AxiosPlugin from 'vue-axios-cors';
/* eslint-disable */
import { required, email, minLength } from 'vuelidate/lib/validators'
// import jsonpAdapter from 'axios-jsonp'
import {TheMask} from 'vue-the-mask'
import Swal from 'sweetalert2'
import Multiselect from 'vue-multiselect'
export default Vue.extend({
    components:{
        TheMask,
        Multiselect
    },
    validations:{
        form:{
            franchisingName:{required},
            // franchisingSurname:{required},
            franchisingEstado:{required, minLength:1},
            franchisingCidade:{required, minLength:1},
        },
        franchisingEmail:{email, required},
    },
    data(){
        return{
            loadingCidade:false,
            loadingButton:false,
            franchisingName: '',
            franchisingSurname: '',
            franchisingEmail: '',
            franchisingFone: '',
            franchisingEstado: [],
            franchisingCidade: [],
            estados:[],
            cidades:[],
        }
    },
    mounted(){
        this.axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
            .then((response) => {
                this.estados = response.data;
            })
    },
    methods:{
        scroll(){
            // window.scroll(0, 1)
        },


        carregaCidades(){
            var uf = this.franchisingEstado.id;
            this.franchisingCidade = [];
            if(uf){
                this.loadingCidade = true
                this.axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+uf+'/municipios')
                    .then((response) => {
                        this.loadingCidade = false
                        this.cidades = response.data;
                    }).catch(() => {
                        this.loadingCidade = false
                    })
            }
        },
        async sendFormFranchising(){
            var emailValido = !this.$v.franchisingEmail.$invalid
            var numeroValido = !(this.franchisingFone <= 14)
            var url = ''
            if(process.env.NODE_ENV === 'production'){
                url = 'https://track.eagletrack.com.br/api'
            }else{
                url = 'http://127.0.0.1:8000/api'
            }
            if((numeroValido || emailValido) && this.$v.form.$invalid){
                this.loadingButton = true
                var objFranchising = {
                    'nome': this.franchisingName,
                    'sobrenome': this.franchisingSurname,
                    'email': this.franchisingEmail,
                    'telefone': this.franchisingFone,
                    'estado': this.franchisingEstado.nome,
                    'cidade': this.franchisingCidade.nome,
                }
                const options = {
                    // https://track.eagletrack.com.br/api
                    url: `${url}/apresentacao/eagle/arquivo/email`,
                    method: 'POST',
                    data: {
                        objFranchising
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                this.$axios(options)
                    .then(() => {
                        this.loadingButton      = false
                        this.franchisingName    = ''
                        this.franchisingFone    = ''
                        this.franchisingEmail   = ''
                        this.franchisingEstado  = []
                        this.franchisingCidade  = []
                        this.franchisingSurname = ''
                        Swal.fire({
                            icon: 'success',
                            title: 'Obrigado!',
                            html: '<apan> Retornaremos seu contato o mais breve possível. </span>',
                            showConfirmButton: false,
                            timer: 4500
                        })
                    }).catch(() => {
                        this.loadingButton = false
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops... <br> Algo inesperado aconteceu',
                            html: '<apan> Tente novamente mais tarde</span> <br> <span class="small"> Verifique sua internet </span>',
                            footer: '',
                        })
                    })
            }else{
                if(!this.franchisingName){
                    this.addClassRed('franchisingName')
                }
                if(!(this.franchisingFone.length >= 14)){
                    this.addClassRed('franchisingFone')
                }
                if(!emailValido){
                    this.addClassRed('franchisingEmail')
                } 
            }
            
        },
        addClassRed(id){
            var elemento = document.getElementById(id);
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('bordaError');
            if (getIndex === -1) {
                classes.push('bordaError');
                elemento.className = classes.join(' ');
            }
            setTimeout(() => {
                this.removeClassRed(id)
            }, 3000);
        },
        removeClassRed(id){
            var elemento = document.getElementById(id);
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('bordaError');
            if (getIndex > -1) {
                classes.splice(getIndex, 1);
            }
            elemento.className = classes.join(' ');
        },
    }
})
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
    @media screen and (max-width: 571px) {
        div.desktop {
            display: none;
            text-align: right;
        }
    }
    @media screen and (min-width: 569px) {
        div.mobile {
            text-align: left;
            display: none;
        }
        
    }
    #formFranchising{
        padding-top: 30px !important;
        background-color: #313131;
        .divLeft{
            // text-align: left;
            font-size: 40px;
            font-family: nexabold;
            padding-top: 35px;
            padding-bottom: 35px;
            .spanBlueLetter{
                color: #00B9DB;
            }
            .spanWhiteLetter{
                color: #fff;
            }
        }
        .divRight{
            padding-top: 35px;
            padding-bottom: 35px;
            .divInputForm{
                padding-bottom: 15px;
                .divDescriptionInput{
                    text-align: left;
                    color: #fff;
                    font-weight: bold;
                }
                .form-control{
                    border-radius: 0px !important;
                }
                .multiselect__tags{
                    border-radius: 0px;
                    .multiselect__tags-wrap{
                        .multiselect__tag{
                            background-color: #2196f3;
                            border-radius: 0px;
                            .multiselect__tag-icon{
                                border-radius: 0px;
                                &::after{
                                    color: #0e5496;
                                }
                                &:hover{
                                    background-color: #1e80da;
                                }
                            }
                        }
                    }
                }
                .multiselect__content-wrapper{
                    .multiselect__content{
                        .multiselect__element{
                            .multiselect__option--highlight{
                                background-color: #2196f3;
                                z-index: 2;
                                &:after{
                                    background-color: #2196f3;
                                }
                            }
                        }
                    }
                }
            }
            .divButtonForm{
                .buttonSendFormFranchising{
                    float: right;
                    font-size: 17px;
                    color: #fff;
                    text-decoration: none;
                    width: 35%;
                    font-weight: bold;
                    padding: 5px;
                    background: #32b8dd;
                    border: none;
                }
            }
        }
    }
</style>