<template>
    <div class="col-sm-12 nopadding row" id="franchising">
        <div class="col-sm-12 nopadding">
            <img 
                src="@/assets/images/franchising2.png" 
                class="divImage" 
                alt="Seja um franqueado Eagle Track"
            >
        </div>
        <div class="col-sm-12 nopadding divImage2Block">
            <img 
                src="@/assets/images/franchising1.png" 
                class="divImage" 
                alt="Vantagens em ser um franqueado Eagle Track"
            >
        </div>
        <div class="col-sm-12 nopadding">
            <img 
                src="@/assets/images/bannerContato.png" 
                class="divImage" 
                alt="Motivos para ser um franqueado Eagle Track"
            >
        </div>
        <div class="col-sm-12 divImageABF">
            <img 
                src="@/assets/images/logoAbf.png" 
                class="divImage" 
                alt="Logo Da ABF Associação Brasileira de Franchising"
            >
        </div>
    </div>    
</template>

<script lang="js">
import Vue from 'vue'
export default Vue.extend({
    methods:{
        handleScroll(){
            const els = document.querySelectorAll('.divImage2Block')
            els.forEach((el)  => {
                if(el.getBoundingClientRect().top <= 914){
                    el.style.animationPlayState = 'running'
                }
            })
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
})
</script>

<style lang="scss">
    #franchising{
        padding-top: 30px !important;

        .divImage{
            width: 100%;
        }

        .divImage2Block{
            animation: animation-img-clip 2s cubic-bezier(.17,.67,.49,1.02);
            animation-play-state: paused;
            animation-fill-mode: forwards;
            .divImage{
                width: 45%;
                position: relative;
                bottom: 40%;
                transition:1s;
                &:hover{
                    animation: pulse 2s infinite;
                    transform:scale(1.2)
                }
            }
        }
        @-webkit-keyframes pulse {
            0%{
                -webkit-box-shadow: 0 0 0 0 rgba(12, 16, 226, 0.4);
            }
            70%{
                -webkit-box-shadow: 0 0 0 40px rgba(7, 7, 7, 0);
            }
            100%{
                -webkit-box-shadow: 0 0 0 0 rgba(156, 155, 148, 0);
            }
        }
        @keyframes pulse {
            0%{
                -moz-box-shadow: 0 0 0 0 rgba(13, 63, 228, 0.4);
                box-shadow: 0 0 0 0 rgba(77, 76, 74, 0.4);
            }
            70%{
                -moz-box-shadow: 0 0 0 40px rgba(204,169,44, 0);
                box-shadow: 0 0 0 40px rgba(12, 12, 12, 0);
            }
            100%{
                -moz-box-shadow: 0 0 0 0 rgba(24, 9, 224, 0);
                box-shadow: 0 0 0 0 rgba(34, 34, 33, 0);
            }
        }
        @keyframes animation-img-clip{
            to{
                clip-path: circle(70.7% at 50% 50%);
            }
            from{
                clip-path: circle(0% at 50% 50%);
            }
        }

        .divImageABF{
            padding: 0px 5%;
            .divImage{
                width: 20%;
                float: left;
            }
        }
    }
</style>